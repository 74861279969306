@import '~app/mixins';

.launchControl {
  height: 100%;
  position: relative;

  .footer {
    position: absolute;
    bottom: 20px;
    right: 796px;
    font-size: 11px;
    color: #999;
    padding-top: 8px;

    button {
      min-width: 130px;

      & + button {
        margin-left: 30px;
      }
    }
  }
}
