@use "sass:map";
@import './variables';
@import './themes';

@mixin transition($attr) {
  transition: $attr $transition-delay $default-animation;
}

@mixin text-overflow() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin placeholder($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }

  &::-moz-placeholder {
    color: $color;
  }

  &:-ms-input-placeholder {
    color: $color;
  }

  &::-ms-input-placeholder {
    color: $color;
  }
}

@mixin themify($property, $key, $themes, $before: '', $after: '') {

  /* stylelint-disable */
  @each $theme, $props in $themes {
    :global(.theme-#{$theme}) & {
      #{$property}: map-get($props, $key);
    }
  }

  /* stylelint-enable */
}

@mixin brand-themify($property, $key, $before: '', $after: '') {
  @include themify($property, $key, $brands, $before, $after);
}

@mixin primary-color() {
  @include brand-themify('color', 'primary-color');
}

@mixin primary-background-color() {
  @include brand-themify('background-color', 'primary-color');
}

@mixin primary-border-color() {
  @include brand-themify('border-color', 'primary-color');
}

@mixin primary-border-top-color() {
  @include brand-themify('border-top-color', 'primary-color');
}

@mixin primary-border-right-color() {
  @include brand-themify('border-right-color', 'primary-color');
}

@mixin primary-border-bottom-color() {
  @include brand-themify('border-bottom-color', 'primary-color');
}

@mixin primary-border-left-color() {
  @include brand-themify('border-left-color', 'primary-color');
}

@mixin primary-fill-color() {
  @include brand-themify('fill', 'primary-color');
}

@mixin primary-color-box-shadow($spread: '2px', $blur: '0') {
  @include brand-themify('box-shadow', 'primary-color', '0 0 #{$blur} #{$spread} ');
}

@mixin primary-color-box-shadow-inset() {
  @include brand-themify('box-shadow', 'primary-color', '0 0 0 2px ', ' inset');
}

@mixin primary-color-outline() {
  @include brand-themify('outline', 'primary-color', '1px solid ');
}

@mixin primary-color-outline-color() {
  @include brand-themify('outline-color', 'primary-color');
}

@mixin primary-color-dark() {
  @include brand-themify('color', 'primary-color-dark');
}

@mixin primary-background-color-dark() {
  @include brand-themify('background-color', 'primary-color-dark');
}

@mixin primary-border-color-dark() {
  @include brand-themify('border-color', 'primary-color-dark');
}

@mixin primary-fill-color-dark() {
  @include brand-themify('fill', 'primary-color-dark');
}

@mixin primary-background-color-light() {
  @include brand-themify('background-color', 'primary-color-light');
}


// Padding and Margins // Use like this (.pt-10, .pt-5 ..., .mt-10, .mt-5 ...)
$spaceamounts: (
  0,
  2,
  3,
  5,
  6,
  7,
  8,
  10,
  12,
  15,
  16,
  17,
  18,
  20,
  22,
  24,
  25,
  28,
  30,
  32,
  33,
  34,
  35,
  40,
  42,
  43,
  44,
  45,
  48,
  49,
  50,
  52,
  53,
  54,
  55,
  57,
  58,
  60,
  62,
  63,
  65,
  67,
  68,
  70,
  72,
  74,
  75,
  76,
  77,
  79,
  80,
  82,
  83,
  85,
  86,
  87,
  88,
  90,
  92,
  93,
  95,
  96,
  98,
  99,
  100
);
$sides: (
  top,
  bottom,
  left,
  right
);

@each $space in $spaceamounts {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}_#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p#{str-slice($side, 0, 1)}_#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

.m_0 {
  margin: 0px !important;
}

.p_0 {
  padding: 0px !important;
}

.mb_5px,
.my_5px {
  margin-bottom: 5px !important;
}

.mt_5px,
.my_5px {
  margin-top: 5px !important;
}

.ml_5px,
.mx_5px {
  margin-left: 5px !important;
}

.mr_5px,
.mx_5px {
  margin-right: 5px !important;
}

.ml__5px,
.mx__5px {
  margin-left: -5px;
}

.mr__5px,
.mx__5px {
  margin-right: -5px;
}

.mb__5px,
.my__5px {
  margin-bottom: -5px;
}

.mt__5px,
.my__5px {
  margin-top: -5px;
}

.ml__10,
.mx__10 {
  margin-left: -10px;
}

.mr__10,
.mx__10 {
  margin-right: -10px;
}

.mb__10,
.my__10 {
  margin-bottom: -10px;
}

.mt__10,
.my__10 {
  margin-top: -10px;
}

.ml__15,
.mx__15 {
  margin-left: -15px;
}

.mr__15,
.mx__15 {
  margin-right: -15px;
}

.mb__15,
.my__15 {
  margin-bottom: -15px;
}

.mt__15,
.my__15 {
  margin-top: -15px;
}

.ml__20,
.mx__20 {
  margin-left: -20px;
}

.mr__20,
.mx__20 {
  margin-right: -20px;
}

.mb__20,
.my__20 {
  margin-bottom: -20px;
}

.mt__20,
.my__20 {
  margin-top: -20px;
}

.pb_5px,
.py_5px {
  padding-bottom: 5px !important;
}

.pt_5px,
.py_5px {
  padding-top: 5px !important;
}

.pl_5px,
.px_5px {
  padding-left: 5px !important;
}

.pr_5px,
.px_5px {
  padding-right: 5px !important;
}

// Font Sizes
@mixin fontSize($sizeValue: 1.6) {
  .text_#{$sizeValue} {
    font-size: ($sizeValue) + px;
    line-height: ($sizeValue) + px;
  }
}

@include fontSize(12);
@include fontSize(13);
@include fontSize(14);
@include fontSize(15);
@include fontSize(16);
@include fontSize(17);
@include fontSize(18);
@include fontSize(19);
@include fontSize(20);
@include fontSize(21);
@include fontSize(22);
@include fontSize(23);
@include fontSize(24);
@include fontSize(25);
@include fontSize(26);
@include fontSize(27);
@include fontSize(28);
@include fontSize(29);
@include fontSize(30);

.text_m {
  font-weight: 500 !important;
}

.text_sb {
  font-weight: 600 !important;
}

.text_b {
  font-weight: 700 !important;
}

.text_center {
  text-align: center !important;
}

.text_right {
  text-align: right !important;
}

.text_left {
  text-align: left !important;
}

.d_block {
  display: block !important;
}

.d_flex {
  display: flex !important;
}

.d_inline_flex {
  display: inline-flex !important;
}

.d_inline_block {
  display: inline-block !important;
}

.align_items_stretch {
  align-items: stretch !important;
}

.align_items_start {
  align-items: flex-start !important;
}

.align_items_end {
  align-items: flex-end !important;
}

.align_items_center {
  align-items: center !important;
}

.justify_content_between {
  justify-content: space-between !important;
}

.justify_content_center {
  justify-content: center !important;
}

.justify_content_end {
  justify-content: flex-end !important;
}

.justify_content_start {
  justify-content: flex-start !important;
}

.flex_row {
  flex-direction: row !important;
}

.flex_row_reverse {
  flex-direction: row-reverse !important;
}

.flex_column_reverse {
  flex-direction: column-reverse !important;
}

.flex_column {
  flex-direction: column !important;
}

.flex_fill {
  flex: 1 1 auto !important;
}

.flex_grow_1 {
  flex-grow: 1 !important;
}

.flex_grow_0 {
  flex-grow: 0 !important;
}

.flex_wrap {
  flex-wrap: wrap !important;
}

.flex_nowrap {
  flex-wrap: nowrap !important;
}

// Small devices (576px and up)
@media (min-width: 576px) {
  $spaceamounts: (
    0,
    2,
    3,
    5,
    6,
    7,
    8,
    10,
    12,
    15,
    16,
    17,
    18,
    20,
    22,
    24,
    25,
    28,
    30,
    32,
    33,
    34,
    35,
    40,
    42,
    43,
    44,
    45,
    48,
    50,
    52,
    53,
    54,
    55,
    57,
    58,
    60,
    62,
    63,
    65,
    67,
    68,
    70,
    72,
    74,
    75,
    76,
    77,
    79,
    80,
    82,
    83,
    85,
    86,
    87,
    88,
    90,
    92,
    93,
    95,
    96,
    98,
    99,
    100
  );
$sides: (
  top,
  bottom,
  left,
  right
);

@each $space in $spaceamounts {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}_sm_#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p#{str-slice($side, 0, 1)}_sm_#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

.text_sm_center {
  text-align: center !important;
}

.text_sm_right {
  text-align: right !important;
}

.text_sm_left {
  text-align: left !important;
}

.d_sm_block {
  display: block !important;
}

.d_sm_flex {
  display: flex !important;
}

.d_sm_inline_flex {
  display: inline-flex !important;
}

.d_sm_inline_block {
  display: inline-block !important;
}

.align_items_sm_stretch {
  align-items: stretch !important;
}

.align_items_sm_start {
  align-items: flex-start !important;
}

.align_items_sm_end {
  align-items: flex-end !important;
}

.align_items_sm_center {
  align-items: center !important;
}

.justify_content_sm_between {
  justify-content: space-between !important;
}

.justify_content_sm_center {
  justify-content: center !important;
}

.justify_content_sm_end {
  justify-content: flex-end !important;
}

.justify_content_sm_start {
  justify-content: flex-start !important;
}

.flex_sm_row {
  flex-direction: row !important;
}

.flex_sm_row_reverse {
  flex-direction: row-reverse !important;
}

.flex_sm_column_reverse {
  flex-direction: column-reverse !important;
}

.flex_sm_column {
  flex-direction: column !important;
}

.flex_sm_fill {
  flex: 1 1 auto !important;
}

.flex_sm_grow_1 {
  flex-grow: 1 !important;
}

.flex_sm_grow_0 {
  flex-grow: 0 !important;
}

.flex_sm_wrap {
  flex-wrap: wrap !important;
}

.flex_sm_nowrap {
  flex-wrap: nowrap !important;
}

.clsAccPageModals {
  .modal_lg {
    max-height: 100%;
  }
}
}

// Medium devices ( 768px and up)
@media (min-width: 768px) {
  $spaceamounts: (
  0,
  2,
  3,
  5,
  6,
  7,
  8,
  10,
  12,
  15,
  16,
  17,
  18,
  20,
  22,
  24,
  25,
  28,
  30,
  32,
  33,
  34,
  35,
  40,
  42,
  43,
  44,
  45,
  48,
  50,
  52,
  53,
  54,
  55,
  57,
  58,
  60,
  62,
  63,
  65,
  67,
  68,
  70,
  72,
  74,
  75,
  76,
  77,
  79,
  80,
  82,
  83,
  85,
  86,
  87,
  88,
  90,
  92,
  93,
  95,
  96,
  98,
  99,
  100
);
$sides: (
  top,
  bottom,
  left,
  right
);

@each $space in $spaceamounts {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}_md_#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p#{str-slice($side, 0, 1)}_md_#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

.text_md_center {
  text-align: center !important;
}

.text_md_right {
  text-align: right !important;
}

.text_md_left {
  text-align: left !important;
}

.d_md_block {
  display: block !important;
}

.d_md_flex {
  display: flex !important;
}

.d_md_inline_flex {
  display: inline-flex !important;
}

.d_md_inline_block {
  display: inline-block !important;
}

.align_items_md_stretch {
  align-items: stretch !important;
}

.align_items_md_start {
  align-items: flex-start !important;
}

.align_items_md_end {
  align-items: flex-end !important;
}

.align_items_md_center {
  align-items: center !important;
}

.justify_content_md_between {
  justify-content: space-between !important;
}

.justify_content_md_center {
  justify-content: center !important;
}

.justify_content_md_end {
  justify-content: flex-end !important;
}

.justify_content_md_start {
  justify-content: flex-start !important;
}

.flex_md_row {
  flex-direction: row !important;
}

.flex_md_row_reverse {
  flex-direction: row-reverse !important;
}

.flex_md_column_reverse {
  flex-direction: column-reverse !important;
}

.flex_md_column {
  flex-direction: column !important;
}

.flex_md_fill {
  flex: 1 1 auto !important;
}

.flex_md_grow_1 {
  flex-grow: 1 !important;
}

.flex_md_grow_0 {
  flex-grow: 0 !important;
}

.flex_md_wrap {
  flex-wrap: wrap !important;
}

.flex_md_nowrap {
  flex-wrap: nowrap !important;
}

.clsBillingModal {
  .modal_lg {
    max-width: 700px;
  }
}
}

// Large devices (992px and up)
@media (min-width: 992px) {
  $spaceamounts: (
  0,
  2,
  3,
  5,
  6,
  7,
  8,
  10,
  12,
  15,
  16,
  17,
  18,
  20,
  22,
  24,
  25,
  28,
  30,
  32,
  33,
  34,
  35,
  40,
  42,
  43,
  44,
  45,
  48,
  50,
  52,
  53,
  54,
  55,
  57,
  58,
  60,
  62,
  63,
  65,
  67,
  68,
  70,
  72,
  74,
  75,
  76,
  77,
  79,
  80,
  82,
  83,
  85,
  86,
  87,
  88,
  90,
  92,
  93,
  95,
  96,
  98,
  99,
  100
);
$sides: (
  top,
  bottom,
  left,
  right
);

@each $space in $spaceamounts {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}_lg_#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p#{str-slice($side, 0, 1)}_lg_#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

.text_lg_center {
  text-align: center !important;
}

.text_lg_right {
  text-align: right !important;
}

.text_lg_left {
  text-align: left !important;
}

.d_lg_block {
  display: block !important;
}

.d_lg_flex {
  display: flex !important;
}

.d_lg_inline_flex {
  display: inline-flex !important;
}

.d_lg_inline_block {
  display: inline-block !important;
}

.align_items_lg_stretch {
  align-items: stretch !important;
}

.align_items_lg_start {
  align-items: flex-start !important;
}

.align_items_lg_end {
  align-items: flex-end !important;
}

.align_items_lg_center {
  align-items: center !important;
}

.justify_content_lg_between {
  justify-content: space-between !important;
}

.justify_content_lg_center {
  justify-content: center !important;
}

.justify_content_lg_end {
  justify-content: flex-end !important;
}

.justify_content_lg_start {
  justify-content: flex-start !important;
}

.flex_lg_row {
  flex-direction: row !important;
}

.flex_lg_row_reverse {
  flex-direction: row-reverse !important;
}

.flex_lg_column_reverse {
  flex-direction: column-reverse !important;
}

.flex_lg_column {
  flex-direction: column !important;
}

.flex_lg_fill {
  flex: 1 1 auto !important;
}

.flex_lg_grow_1 {
  flex-grow: 1 !important;
}

.flex_lg_grow_0 {
  flex-grow: 0 !important;
}

.flex_lg_wrap {
  flex-wrap: wrap !important;
}

.flex_lg_nowrap {
  flex-wrap: nowrap !important;
}

body {
  .clsAccUpdateProfileModal {

    // .modal_lg{
    //   width: 667px;
    // }
    .clsModalBody {
      
      .row {
        margin: 0;
        display: flex;

        .col_lg_6 {
          padding: 0;
          flex: 0 0 257.5px;
          max-width: 257.5px;
          margin-right: 24px;

          &:last-child {
            margin: 0;
          }
        }
      }
    } 
  }

  .clsAccEditProfileModal {
    .clsModalBody { 
      .rowInfo {
        margin: 0;
        display: flex;

        .col_lg_8 {
          padding: 0;
          flex: 0 0 387px;
          max-width: 387px;
          margin-right: 24px;

          &:last-child {
            margin: 0;
          }
        }

        .col_lg_4{
          padding: 0;
          flex: 0 0 128px;
          max-width: 128px; 
        }

        .rowInfoInner{
          margin: 0;
          display: flex;
          .col_lg_6{
            padding: 0;
            flex: 0 0 181.5px;
            max-width: 181.5px;
            margin-right: 24px;

          &:last-child {
            margin: 0;
          }
          }
        }
      }

      .rowAddress{
        margin: 0;
        display: flex;

        .col_lg_6 {
          padding: 0;
          flex: 0 0 257.5px;
          max-width: 257.5px;
          margin-right: 24px;

          &:last-child {
            margin: 0;
          }
        }

        .rowAddressInner{
          margin: 0;
          display: flex;
  
          .col_lg_6 {
            padding: 0;
            flex: 0 0 116.75px;
            max-width: 116.75px;
            margin-right: 24px;
  
            &:last-child {
              margin: 0;
            }
          }
        }
      }
    } 
  }

  .clsAccEditMarketingProfileModal{
    .clsModalBody { 
      .rowInfo1 {
        margin: 0;
        display: flex;

        .col_lg_8 {
          padding: 0;
          flex: 0 0 387px;
          max-width: 387px;
          margin-right: 24px;

          &:last-child {
            margin: 0;
          }
        }

        .col_lg_4{
          padding: 0;
          flex: 0 0 128px;
          max-width: 128px; 
        }

        .rowInfoInner{
          margin: 0;
          display: flex;
          .col_lg_6{
            padding: 0;
            flex: 0 0 181.5px;
            max-width: 181.5px;
            margin-right: 24px;

          &:last-child {
            margin: 0;
          }
          }
        }
      }

      .rowInfo2{
        margin: 0;
        display: flex;

        .col_lg_6 {
          padding: 0;
          flex: 0 0 257.5px;
          max-width: 257.5px;
          margin-right: 24px;

          &:last-child {
            margin: 0;
          }
        }
      }

      .rowAddress{
        margin: 0;
        display: flex;

        .col_lg_6 {
          padding: 0;
          flex: 0 0 257.5px;
          max-width: 257.5px;
          margin-right: 24px;

          &:last-child {
            margin: 0;
          }
        }

        .rowAddressInner{
          margin: 0;
          display: flex;
  
          .col_lg_6 {
            padding: 0;
            flex: 0 0 116.75px;
            max-width: 116.75px;
            margin-right: 24px;
  
            &:last-child {
              margin: 0;
            }
          }
        }
      }

    }
  }

  .clsAccPrepaidCreditsModal{
    .clsModalBody { 
      .clsModalBodyInner{
        max-width: 332px; 
      }
    }
  }
}

}

// X-Large devices (1200px and up)
@media (min-width: 1200px) {
  $spaceamounts: (
    0,
    2,
    3,
    5,
    6,
    7,
    8,
    10,
    12,
    15,
    16,
    17,
    18,
    20,
    22,
    24,
    25,
    28,
    30,
    32,
    33,
    34,
    35,
    40,
    42,
    43,
    44,
    45,
    48,
    50,
    52,
    53,
    54,
    55,
    57,
    58,
    60,
    62,
    63,
    65,
    67,
    68,
    70,
    72,
    74,
    75,
    76,
    77,
    79,
    80,
    82,
    83,
    85,
    86,
    87,
    88,
    90,
    92,
    93,
    95,
    96,
    98,
    99,
    100
  );
$sides: (
  top,
  bottom,
  left,
  right
);

@each $space in $spaceamounts {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}_xl_#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p#{str-slice($side, 0, 1)}_xl_#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

.text_xl_center {
  text-align: center !important;
}

.text_xl_right {
  text-align: right !important;
}

.text_xl_left {
  text-align: left !important;
}

.d_xl_block {
  display: block !important;
}

.d_xl_flex {
  display: flex !important;
}

.d_xl_inline_flex {
  display: inline-flex !important;
}

.d_xl_inline_block {
  display: inline-block !important;
}

.align_items_xl_stretch {
  align-items: stretch !important;
}

.align_items_xl_start {
  align-items: flex-start !important;
}

.align_items_xl_end {
  align-items: flex-end !important;
}

.align_items_xl_center {
  align-items: center !important;
}

.justify_content_xl_between {
  justify-content: space-between !important;
}

.justify_content_xl_center {
  justify-content: center !important;
}

.justify_content_xl_end {
  justify-content: flex-end !important;
}

.justify_content_xl_start {
  justify-content: flex-start !important;
}

.flex_xl_row {
  flex-direction: row !important;
}

.flex_xl_row_reverse {
  flex-direction: row-reverse !important;
}

.flex_xl_column_reverse {
  flex-direction: column-reverse !important;
}

.flex_xl_column {
  flex-direction: column !important;
}

.flex_xl_fill {
  flex: 1 1 auto !important;
}

.flex_xl_grow_1 {
  flex-grow: 1 !important;
}

.flex_xl_grow_0 {
  flex-grow: 0 !important;
}

.flex_xl_wrap {
  flex-wrap: wrap !important;
}

.flex_xl_nowrap {
  flex-wrap: nowrap !important;
}
}

// XX-Large devices (1400px and up)
@media (min-width: 1400px) {
  $spaceamounts: (
  0,
  2,
  3,
  5,
  6,
  7,
  8,
  10,
  12,
  15,
  16,
  17,
  18,
  20,
  22,
  24,
  25,
  28,
  30,
  32,
  33,
  34,
  35,
  40,
  42,
  43,
  44,
  45,
  48,
  50,
  52,
  53,
  54,
  55,
  57,
  58,
  60,
  62,
  63,
  65,
  67,
  68,
  70,
  72,
  74,
  75,
  76,
  77,
  79,
  80,
  82,
  83,
  85,
  86,
  87,
  88,
  90,
  92,
  93,
  95,
  96,
  98,
  99,
  100
);
$sides: (
  top,
  bottom,
  left,
  right
);

@each $space in $spaceamounts {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}_xxl_#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p#{str-slice($side, 0, 1)}_xxl_#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

.text_xxl_center {
  text-align: center !important;
}

.text_xxl_right {
  text-align: right !important;
}

.text_xxl_left {
  text-align: left !important;
}

.d_xxl_block {
  display: block !important;
}

.d_xxl_flex {
  display: flex !important;
}

.d_xxl_inline_flex {
  display: inline-flex !important;
}

.d_xxl_inline_block {
  display: inline-block !important;
}

.align_items_xxl_stretch {
  align-items: stretch !important;
}

.align_items_xxl_start {
  align-items: flex-start !important;
}

.align_items_xxl_end {
  align-items: flex-end !important;
}

.align_items_xxl_center {
  align-items: center !important;
}

.justify_content_xxl_between {
  justify-content: space-between !important;
}

.justify_content_xxl_center {
  justify-content: center !important;
}

.justify_content_xxl_end {
  justify-content: flex-end !important;
}

.justify_content_xxl_start {
  justify-content: flex-start !important;
}

.flex_xxl_row {
  flex-direction: row !important;
}

.flex_xxl_row_reverse {
  flex-direction: row-reverse !important;
}

.flex_xxl_column_reverse {
  flex-direction: column-reverse !important;
}

.flex_xxl_column {
  flex-direction: column !important;
}

.flex_xxl_fill {
  flex: 1 1 auto !important;
}

.flex_xxl_grow_1 {
  flex-grow: 1 !important;
}

.flex_xxl_grow_0 {
  flex-grow: 0 !important;
}

.flex_xxl_wrap {
  flex-wrap: wrap !important;
}

.flex_xxl_nowrap {
  flex-wrap: nowrap !important;
}

.flex_xxl_wrap {
  flex-wrap: wrap !important;
}

.clsAccPageModals {
  .modalContent {
    border: 0 !important;
  }
}

.clsSearchFilterModal {
  background-color: transparent !important;

  .modalContent {
    background: #F3F5F8 !important;

  }
} 
}

.clsAccPageModalsMain {
  .modalContent {
    background-color: #EFF2F6 !important;
    border-radius: 12px;
    box-shadow: none;
    border: 0;
  }
}
